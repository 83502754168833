<template>
  <div>
    <resetnew />
  </div>
</template>

<script>
// @ is an alias to /src
import resetnew from "@/components/resetnew";

export default {
  name: "reset",
  components: {
    resetnew
  }
};
</script>