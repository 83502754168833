<template>
  <div class="login-page">
    <div class="logo" @click="toindex()" title="返回首页">
      <img src="@/assets/logo.png" />
    </div>
    <div class="bg"></div>
    <div class="ikon">
        <div class="settop">
            <span class="first"><img src="@/assets/finish.png"></span>
            <p class="first_text">身份认证</p>
            <p class="complateline"></p>
            <span class="first">2</span>
            <p class="second_text">重置密码</p>
            <p class="preline"></p>
            <span class="second">3</span>
            <p class="second_text">完成</p>
        </div>
      <div class="reset-form">
        <div>
          <label class="lab"><i class="required2">*</i>新密码</label>
          <input class="input_text" type="password" autocomplete="new-password" v-model="newpwd" name="newpwd" placeholder="请输入新密码" @keyup="enable()" />
        </div>
        <div>
          <label class="lab">&nbsp;&nbsp;<i class="required">*</i>确认新密码</label>
          <input class="input_text" type="password" autocomplete="new-password" v-model="repwd" name="repwd" placeholder="请确认新密码" @keyup="enable()" />
        </div>
        <div class="submit"  >
          <b @click="back()">上一步</b>
          <span  type="danger" size="large" class="nextbtn" @click="next_step()" v-if="step">下一步</span>
          <span  type="danger" size="large" class="btn" v-else @click="unstep()">下一步</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "resetnew",
  // inject:['app'],
  data() {
    return {
      newpwd:"",
      repwd:"",
      step:"false"
    };
  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
  },
  methods: {
    enable(){
      if(this.newpwd.toString().length>=6&&this.newpwd==this.repwd){
        this.step=true;
      }else{
        this.step=false;
      }
    },
    unstep(){
      if(this.newpwd!=this.repwd){
      }
      this.$message({
          message: '请确认两次密码是否一致',
          type: 'warning',
          center:'true'
        });
    },
    back(){
      this.$router.back();
    },
    toindex(){
      this.$router.push("./homepage")
    },
    next_step(){
      var that=this;
        if(this.$route.query.user_type==1){
        var params={'id_card':this.$route.query.idnum,'phone':this.$route.query.moble,'code':this.$route.query.code,'password':this.repwd};
        var api=this.$store.state.user.q_url+'/api/api/Forget_password'
        }else if(this.$route.query.user_type==2){
          var params={'phone':this.$route.query.moble,'code':this.$route.query.code,'password':this.repwd};
          var api=this.$store.state.user.q_url+'/api/api/virtual_password'
        }else if(this.$route.query.user_type==3){
          var params={'phone':this.$route.query.moble,'code':this.$route.query.code,'password':this.repwd};
          var api=this.$store.state.user.q_url+'/api/api/virtual_password'
        }
        $.ajax({
          async:false,
          type:'post',
          url:api,
          data:params,
          dataType:'json',
          success:function(res){
           if(res.success==true){
        that.$router.push("./reset_complated");
        }else{
          that.$message({
          message: res.message,
          type: 'error',
          center:'true'
        })}
          },
          error : function(res) {
            alert('修改失败');
            }
        })
    }
  }
};
</script>

<style lang="less" scoped>
.login-page {
  top: 80px;
  position: fixed;
  padding: 0px;
  margin: 0;
  font-size: 0px;
  height: 80%;
  width: 100%;
  box-sizing: border-box;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(242, 243, 250, 1);
    z-index: -10;
  }
}
.logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: -70px;
  > img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.ikon {
  position: absolute;
  z-index: -1;
  padding: 0;
  align-items: center;
  width: 1300px;
  height: 720px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(222, 222, 222, 1);
  border-radius: 8px;
  > img {
    width: 570px;
    height: 100%;
  }
  .settop{height: 40px;width: 920px;position: absolute;top: 100px;left: 217px;right: 217px;
  >span{display: inline-block;}
  >p{display: inline-block;}
  .first{
      width: 36px;
      height: 36px;
      text-align: center;
      background:rgba(255,255,255,1);
    border:2px solid rgba(222,222,222,1);
    border-radius:50%;
    font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(61,49,226,1);
line-height:36px;
margin-left: 18px;
>img{width: 20px;height: 20px;margin-top: 8px;}
}
.first_text{width:86px;
height:20px;
font-size:20px;
font-family:Microsoft YaHei;
font-weight:600;
color:rgba(55,67,214,1);
line-height:26px;
margin: 0;
margin-left: 18px;
}
.second{width:36px;
height:36px;
text-align: center;
background:rgba(204,204,204,1);
border-radius:50%;
font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(255,255,255,1);
line-height:36px;
margin-left: 18px;}
.second_text{
    width:86px;
height:20px;
font-size:20px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(51,51,51,1);
line-height:26px;
margin: 0;
margin-left: 18px;
}
.preline{width:200px;
height:4px;
background:rgba(204,204,204,1);
border-radius:1px;
margin-left: 18px;
margin-bottom: 5px;}
  }
  .complateline{background:  #3D31E2;
  width:200px;
height:4px;
border-radius:1px;
margin-left: 18px;
margin-bottom: 5px;}
  .reset-form {
    display: inline-block;
    position: absolute;
    right: 0;
    left: 0;
    top: 150px;
    margin: auto;
    height: 400px;
    width: 490px;
    background-color: #fff;
    box-sizing: border-box;
    > div {
      border-radius: 20px;
    }
    > p {
      width: 48px;
      height: 24px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 34px;
    }
    .lab {
      width: 51px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 33px;
      .required{margin-right: 2px;color: #F42C2E;font-style: normal;}
      .required2{margin-right: 2px;color: #F42C2E;font-style: normal;padding-left: 30px;display: inline-block;}
    }
    .input_text {
      outline: none;
      text-indent: 10px;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 400;
      z-index: 10;
      width:280px;
height:38px;
background:rgba(255,255,255,1);
border:2px solid rgba(238,238,238,1);
margin-left: 20px;
margin-top: 24px;
    }
    .submit {
      height: 60px;
      margin: 10px;
      margin-top: 40px;
      position: relative;
      >b{
        color: #aaa;
        font-style: normal;
        font-size: 12px;
        position: absolute;
        left: -60px;
        z-index: 3000;
        top:20px;
      }
      >b:hover{color: #3D31E2;cursor: pointer;}
    }
    .submit .nextbtn {
      width: 360px;
      height: 60px;
      background: rgba(55, 67, 214, 1);
      border-radius: 30px;
      display: inline-block;
      font-size: 22px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,.7);
      line-height: 60px;
      text-align: center;
      cursor: pointer;
    }
    .btn{width: 360px;
      height: 60px;
      background:rgba(153,153,153,1);
      border-radius: 30px;
      display: inline-block;
      font-size: 22px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,.7);
      line-height: 60px;
      text-align: center;
      cursor: pointer;}
  }
}
</style>